import React from 'react';
import Button from '../components/Button';

class BookPageList extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        const {data} = this.props;

        const header = (
            <header>
                <h2>{data[0].title}</h2>
                <p><em>Sociology</em> the study of the origin, the history and the structure of human society and it’s institutions</p>
            </header>
        )

        const BookPageList = data.map((data, index) => {
            return (
                <Button
                    key={index}
                    cClass='card'
                    click={
                        () => this.props.openDetails(index)
                    }
                    icon={true}
                    title={'page ' + (index+1)}
                />
            )
        })

	    return (
            <>
            {header}
            {[BookPageList]}
            </>
    	);
	}
};
export default BookPageList;