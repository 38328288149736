import React from 'react';

export default class Video extends React.Component {
    
    render() {
        const {width, height, controlsList, poster, preload, source, type} = this.props;
        return (
            <video
                width={width}
                height={height}
                controls
                controlsList={controlsList}
                poster={poster}
                preload={preload}
            >
                <source
                    src={source}
                    type={'video/'+ type}
                />
                'Your browser does not support the video tag.'
            </video>
        )
    }
}