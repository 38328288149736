import React from 'react';
import Button from '../components/Button';

class PandemicPageList extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        const {data} = this.props;

        const header = (
            <header>
                <h2>{data[0].title}</h2>
                <p><em>Panecia</em> a cure for all ills, a universal remedy</p>
            </header>
        )

        const PandemicPageList = data.map((data, index) => {
            return (
                <Button
                    key={index}
                    cClass='card'
                    click={
                        () => this.props.openDetails(index)
                    }
                    icon={true}
                    title={'page ' + (index+1)}
                />
            )
        })

	    return (
            <>
            {header}
            {[PandemicPageList]}
            </>
    	);
	}
};
export default PandemicPageList;