import React from 'react';
import PandemicPageList from '../components/PandemicPageList';
import PandemicPageDetail from '../components/PandemicPageDetail';

class Pandemic extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            bookData: [],
            selectedPage: 0,
            details: false,
        }
    }

    componentDidMount() {
        this.getBookData();
        window.scrollTo(0, 0);
    }

    getBookData() {
        const dataURL = "http://solerite.ca/json/pandemic.json";
        fetch(dataURL)
        .then(res => res.json())
        .then(res => {
            this.setState({
                bookData: res,
                loading: false
            })
        });
    }

    detailOpen = (childData) => {
        const selectedPage = this.state;
        this.setState({
            selectedPage: childData,
            details: true,
        })
    }

    detailClose = (childData) => {
        const selectedPage = this.state;
        
        this.setState({
            details: !this.state
        })
        window.scrollTo(0, 0);
    }

    detailPrev = (childData) => {
        const {selectedPage, bookData} = this.state;

        if (selectedPage == 0) {
            this.detailClose();
        }
        this.setState({
            selectedPage: this.state.selectedPage - 1
        })
        window.scrollTo(0, 0);
    }

    detailNext = (childData) => {
        const {selectedPage, bookData} = this.state;
        const poemTotal = bookData.length;
        
        if (selectedPage == poemTotal - 1) {
            this.detailClose();
        }
        this.setState({
            selectedPage: this. state.selectedPage + 1
        })
        window.scrollTo(0, 0);
    }

    render() {
        const { bookData, details, selectedPage} = this.state;
        let imagePath = '_images/pandemic/';
        let pagination = 'page ' + (this.state.selectedPage + 1);
        // console.log('bookData:', bookData);
        
        return (
            <section>
                    {bookData && bookData.length > 0 && details === false
                        ? <>
                            <div className="pandemic__hero" style={{ backgroundImage: `url(${imagePath + bookData[0].hero})` }}/>
                            <article className='pandemic pandemic__list'>
                                <PandemicPageList
                                    data={bookData}
                                    page={pagination}
                                    openDetails={this.detailOpen}
                                />
                            </article>
                        </>
                        : ''
                    }
                    {bookData && bookData.length > 0 && details === true
                        ? <article className='pandemic pandemic__article'>
                            <PandemicPageDetail
                                data={bookData[this.state.selectedPage]}
                                cClass="pandemic__article--content"
                                close={this.detailClose}
                                next={this.detailNext}
                                prev={this.detailPrev}
                                page={pagination}
                            />
                            </article>
                        : ''
                    }
            </section>
        );
    }
};

export default Pandemic;