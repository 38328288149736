import React from 'react';
import Button from '../components/Button';
import Video from '../components/Video';

class PandemicPageDetail extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { data, cClass, divClick, mediaClick, title, content, id } = this.props
        let imagePath = '/_images/pandemic/';
        let videoPath = '_video/';
        let youtubePrefix = "https://www.youtube.com/embed/";

        const header = (
            <header>
                <h2>{data.title}</h2>
                <p><em>Panecia</em> a cure for all ills, a universal remedy</p>
            </header>
        )

        const article = (
            <>
                <div className={cClass}>
                    {data.articleHero ? <img src={imagePath + data.articleHero} alt="" /> : ''}
                    {data.type === 'video'
                        ?
                            <Video
                                poster={imagePath + data.poster}
                                source={videoPath + data.source}
                                type={data.sourceType}
                                controls={true}
                                controlsList="nodownload"
                            />
                        : ''
                    }
                    {data.type === 'youtube'
                        ?
                            <div className="youtube">
                                <iframe
                                    width="560"
                                    height="315"
                                    src={youtubePrefix + data.source}
                                    controls=""
                                    frameBorder="0"
                                    allow="accelerometer; encrypted-media; gyroscope;"
                                    allowFullScreen
                                />
                            </div>
                        : ''
                    }
                    {data.articleTitle ? <><h3>{data.articleTitle}</h3> <time dateTime={data.articleDate}>{data.articleDate}</time> </> : ''}
                    {data.articleContent ? <p>{data.articleContent}</p> : ''}
                </div>
                <span> {this.props.page}</span>
            </>
        )

        const controls = (
            <div className='pandemic__controls'>
                <Button
                    cClass='btn btn__back'
                    click={
                        () => this.props.prev()
                    }
                    icon={true}
                    title='Previous'
                />
                <Button
                    cClass='btn btn__text'
                    click={
                        () => this.props.close()
                    }
                    icon={true}
                    title='Index'
                />
                <Button
                    cClass='btn btn__next'
                    click={
                        () => this.props.next()
                    }
                    icon={true}
                    title='Next'
                />
            </div>
        )

        return (
            <>
            {header}
            {article}
            {controls}
            </>
        )
    }
};
export default PandemicPageDetail;