import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Vacant from './pages/Vacant';
import Automaton from './pages/Automaton';
import Poems from './pages/Poems';
import AuntiSocial from './pages/AuntiSocial';
import Palindrome from './pages/Palindrome';
import Pandemic from './pages/Pandemic';
// import Styleguide from './pages/Styleguide';

function App() {
    return (
        <Router>
            <Header />
            <Switch>
                <Route path="/Vacant" children={<Vacant />} />
                <Route path="/Automaton" children={<Automaton />} />
                <Route path="/Palindrome" children={<Palindrome />} />
                <Route path="/Poems" children={<Poems />} />
                <Route path="/AuntiSocial" children={<AuntiSocial />} />
                <Route path="/Pandemic" children={<Pandemic />} />
                <Route path="/" children={<Home location={location} />} />
            </Switch>
        </Router>
    );
}

export default App;
    // <Route path="/Styleguide">
    //     <Styleguide />
    // </Route>