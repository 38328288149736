import React from 'react';

class Vacant extends React.Component {
	constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let imagePath = '_images/hero/';
	    
        return (
            <section className="page">
                <article className="page__article">
                    <header>
                        <h2>Vacant</h2>
                        <p><em>vacant</em> empty, not filled || not mentally acftive || showing empty headedness</p>
                    </header>
                    <div className="page__article--content">
                        <img src={imagePath + 'hero-IMG_3048.jpg'} alt="" />
                        <p>The first painting that I have chosen to adorn my solerite website is one of an expressionless blue faced androgynous looking woman. She has lost her sentient ability to feel or show expression, and has become blissfully unaware while floating aimlessly with no identity or memory. Haunted by the images and dreamlike flashes of her past her humanity is all but gone.</p>
                        <br />
                        <p>Traveling through the Mediterranean a few years back I purchased this painting in Spain while visiting the island of Palma de Majorca, and I was immediately drawn into the message of the compelling artwork. I believe that this piece is the creation of a Cuban artist by the name of Denis Nunez Rodriguez. The Cuban people are very proud when it comes to family honour, history and tradition. I get the feeling that there were many stories of the descendants from the old country of Spain with their beautiful women, tales that were told in the household of this gifted artist. Recollections from the past that would make you dream of one day experiencing the place of your roots and family origins.</p>
                        <br />
                        <p>Now if you have ever been fortunate enough to travel to sunny Cuba and visit with the wonderful people who live there, you will become aware of how life has not changed as fast as the rest of the world, and you will get a sense of walking into the past. People still act like warm, compassionate and emotional human beings. If this artist in question did finally get the opportunity to visit Spain and have a chance to see and reconnect with the beautiful señoritas that he had heard so much about while growing up, then I think that this could explain what he may have been thinking while painting this striking image. The very moment when he realized how lifeless, mechanized and inhumanly robotized the modern peoples of the world were becoming. Especially if this individual had just been to the theatre to see the James Cameron film called “Avatar”. This is the image that comes to my mind while looking at this masterpiece.</p>
                        <br />
                        <p>It is time that more of us were beginning to become aware of how mankind is now in danger of not only losing his humanity, but also his blessed Soul in the process. A time for us to realize that when our our Solerite can no longer exist in its human host the game is over and there will be no turning back. We will not know what we had in our human grasp until it is gone, and there will not be any do overs. It is now up to us to decide which direction we are to take this planet in. If there is to be a Hell on Earth it will be one of our own making, and one that we have chosen, as it is the one that we have created.</p>
                    </div>
                    <div className="poem__article--content">
                        <div className="youtube">
                            <iframe
                                title="Let The Night Come Through"
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/gByTF6d8mOY"
                                controls=""
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope;"
                                allowFullScreen
                            />
                        </div>
                        <h2>Let The Night Come Through</h2>

                        Seems that our lives have become like some kind of fable<br />
                        Its getting hard just to sit down and float in a boat<br />
                        We can’t come forth and lay our cards on the table<br />
                        Now unable to retain a shred of hope<br />
                        <br />
                        Let the night come through, let the night come through<br />
                        Let the night shine through, and let your dreams come true<br />
                        <br />
                        Time and time had to think about life and survival<br />
                        Can’t get head my round how things have changed from the past<br />
                        It’s like the worlds at the dawn of a brand new arrival<br />
                        Days of old and those ways that can no longer last<br />
                        <br />
                        Let the night come through, let the night come through<br />
                        Let the night shine through, and may your dreams come true<br />
                        <br />
                        How did we give up all those things that we fought for<br />
                        How did we let things get so way out of hand<br />
                        How did we lose sight of everything that had meaning<br />
                        How did this happen with no one who could understand <br />
                        <br />
                        Our life and times have found another direction<br />
                        Try to pick up the pieces that no longer remain<br />
                        Spirit and Soul now attacked from a major deflection<br />
                        So try to live a life we can no longer sustain<br />
                        <br />
                        But let the night come through, let the night come through<br />
                        Let the night shine through, and may your dreams come true
                    </div>
                </article>
            </section>
    	);
	}
};
export default Vacant;