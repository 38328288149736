import React from 'react';

export default class Button extends React.Component {
    
    render() {
        const {type, cClass, click, title, icon, destination, target} =this.props;
        return (
            <>
            {type === 'anchor'
                ? <a
                    className={cClass}
                    onClick={click}
                    href={destination}
                    target={target}
                    rel="norefferer" 
                >
                    {icon
                        ? <i />
                        : ''
                    }
                    {title}
                </a>
                : <div
                    className={cClass}
                    onClick={click}
                >
                    {icon
                        ? <i />
                        : ''
                    }
                    {title}
                </div>
            }
            </>
        )
    }
}