import React from 'react';
import PoemList from '../components/PoemList';
import PoemDetail from '../components/PoemDetail';

class Poems extends React.Component {
	constructor() {
        super();
        this.state = {
            loading: true,
            poemData: [],
            selectedPoem: 0,
            details: false,
        }
    }

    componentDidMount() {
        this.getPoemData();
        window.scrollTo(0, 0);
    }

    getPoemData() {
        const dataURL = "http://solerite.ca/json/poems.json";
        fetch(dataURL)
        .then(res => res.json())
        .then(res => {
            this.setState({
                poemData: res,
                loading: false
            })
        });
    }

    detailOpen = (childData) => {
        const selectedPoem = this.state;
        this.setState({
            selectedPoem: childData,
            details: true,
        })
    }

    detailClose = (childData) => {
        const selectedPoem = this.state;
        
        this.setState({
            details: !this.state
        })
        window.scrollTo(0, 0);
    }

    detailPrev = (childData) => {
        const {selectedPoem, poemData} = this.state;

        if (selectedPoem == 0) {
            this.detailClose();
        }
        this.setState({
            selectedPoem: this.state.selectedPoem - 1
        })
        window.scrollTo(0, 0);
    }

    detailNext = (childData) => {
        const {selectedPoem, poemData} = this.state;
        const poemTotal = poemData.length;
        
        if (selectedPoem == poemTotal - 1) {
            this.detailClose();
        }
        this.setState({
            selectedPoem: this.state.selectedPoem + 1
        })
        window.scrollTo(0, 0);
    }

    render() {
        const { poemData, details, selectedPoem} = this.state;
        let imagePath = '_images/naomi/';
        // console.log('poemData:', poemData);
	    
        return (
            <section>
                    {poemData && poemData.length > 0 && details === false
                        ? <>
                            <div className="poem__hero" style={{ backgroundImage: `url(${imagePath + poemData[0].hero})` }}/>
                            <article className='poem poem__list'>
                                <PoemList
                                    data={poemData}
                                    openDetails={this.detailOpen}
                                />
                                </article>
                            </>
                        : ''
                    }

                    {poemData && poemData.length > 0 && details === true
                        ? <>
                            <article className='poem poem__article'>
                                <PoemDetail
                                    data={poemData[this.state.selectedPoem]}
                                    cClass="poem__article--content"
                                    close={this.detailClose}
                                    next={this.detailNext}
                                    prev={this.detailPrev}
                                />
                            </article>
                            </>
                        : ''
                    }
            </section>
    	);
	}
};
export default Poems;