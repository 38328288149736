import React from 'react';
import Video from '../components/Video';

class Automata extends React.Component {
	constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let imagePath = '_images/hero/';
	    
        return (
            <section className="page">
                <article className="page__article">
                    <header>
                        <h2>Automaton</h2>
                        <p><em>automaton</em> a person or animal whose actions are not controlled by his own will or intelligence  ||  a robot</p>
                    </header>
                    <div className="page__article--content">
                        <img src={imagePath + 'automata.jpg'} alt="" />
                        <p>I was wandering around the local antiques market when I came across this somewhat surrealistic painting signed by an artist named Manuel. I  do not know anything about this individual and the work is untitled, but I was immediately drawn towards the message that I felt this man was trying to convey. How our human species is rapidly devolving into a race of automatons, blissfully unaware of their surroundings as they stride towards their own demise.</p>
                        <br />
                        <p>Our central character is a lifeless Pinocchio styled wooden man who finds out that the more he lies to himself about his denial of reality, the more he gets caught up into the world wide web until it is too late as he has reached the point of no return. This zombified non human bears a clock upon his head symbolizing the best before date of an environment in which he is now embroiled where time is running out.</p>
                        <br />
                        <p>The significance of this piece reminded me in a visual expression, of what I was trying to say verbally at the turn of the century, in my lyric for a song that I wrote titled “The Computer Age”. Especially in the words “You’re caught inside a web, and you will go where you are led. It’s just a symptom of the times”. I penned those words before 1999, Y2K, and the New Millennium, long before everyone had a computer, cellphone, or Twitter and Facebook account. Like my poetic assault describing the paradigm shift about to occur, so too does Manuel’s prophetic artistic vision of a world gone amuck, in a land born of schmucks.</p>
                    </div>
                    <div className="poem__article--content">
                        <div className="youtube">
                            <iframe
                                title="the computer age"
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/Jjuked4G7l4"
                                controls=""
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope;"
                                allowFullScreen
                            />
                        </div>

                        <h2>The Computer Age</h2>

                        Your life is passing by<br />
                        Not done much and it makes you cry<br />
                        You just don’t feel like you belong<br />
                        You’re so far out of tune<br />
                        Typing in your rubber room<br />
                        You’re tied to it and it’s stuck on you<br />
                        There is no time at all, not a lot to do at all<br />
                        All you do is take the fall, the computer age<br />
                        There ain’t no winning, ain’t no end or beginning<br />
                        Just a million more innings, the computer age<br />
                        <br />
                        Your curiosity is<br />
                        All but gone you cannot see<br />
                        Just sit there like a jello mold<br />
                        Your spontaneity is<br />
                        All but lost beside the screen<br />
                        It’s a concept that you can’t conceive<br />
                        You speak of survival, but you don’t know your rival<br />
                        Then you’re sued for libel, the computer age<br />
                        Don’t know what love is, you look so blank and you've got no fizz<br />
                        An attitude thats so Les Miz, the computer age, the computer age<br />
                        <br />
                        Your whole reality is<br />
                        Built on unoriginality<br />
                        Plug a program in and watch it pass you by<br />
                        You’re caught inside a web<br />
                        And you will go where you are led<br />
                        It’s just a symptom of the times<br />
                        There is no love at all, you can’t even hear the call<br />
                        All you do is sit and sprawl, the computer age<br />
                        You don’t know what life means, you’re told the grass was always green<br />
                        Out of control and so obscene, the computer age, the computer age<br />
                        The computer age…
                    </div>
                </article>
            </section>
    	);
	}
};
export default Automata;