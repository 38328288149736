import React from 'react';
import Button from '../components/Button';

class PoemList extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        const {data} = this.props;

        const header = (
            <header>
                <h2>{data[0].title}</h2>
                <p><em>Muse</em> the source of a poet’s or artist’s inspiration</p>
            </header>
        )

        const PoemList = data.map((data, index) => {
            return (
                <Button
                    key={index}
                    cClass='card'
                    click={
                        () => this.props.openDetails(index)
                    }
                    icon={true}
                    title={data.articleTitle}
                />
            )
        })

	    return (
            <>
            {header}
            {[PoemList]}
            </>
    	)
	}
};
export default PoemList;