import React from 'react';
import Button from '../components/Button';

class PoemDetail extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {data, cClass, divClick, mediaClick, title, content} = this.props
        const imagePath = '/_images/hero/';

        const header = (
            <header>
                <h2>{data.title}</h2>
                <p><em>Muse</em> the source of a poet’s or artist’s inspiration</p>
            </header>
        )

        const article = (
            <div className={cClass}>
                {data.articleHero ? <img src={imagePath + data.articleHero} alt="" /> : ''}
                {data.articleTitle ? <h3>{data.articleTitle}</h3> : ''}
                {data.articleContent ? <p>{data.articleContent}</p> : ''}
            </div>
        )

        const controls = (
            <div className='poem__controls'>
                <Button
                    cClass='btn btn__back'
                    click={
                        () => this.props.prev()
                    }
                    icon={true}
                    title='Previous'
                />
                <Button
                    cClass='btn btn__text'
                    click={
                        () => this.props.close()
                    }
                    icon={true}
                    title='Index'
                />
                <Button
                    cClass='btn btn__next'
                    click={
                        () => this.props.next()
                    }
                    icon={true}
                    title='Next'
                />
            </div>
        )

        return (
            <>
            {header}
            {article}
            {controls}
            </>
        )
    }
};
export default PoemDetail;